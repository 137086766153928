

.header{

  &__title{
    margin-bottom: 2.875rem;
    display: block;
    max-width: 681px;
    width: 100%;
  }

  &__logos{
    display: flex;
    align-items: center;
    justify-content: center;

    &__separation{
      margin: 0 2rem;
    }
  }

  &__container{
    width: 100%;
    height: 100vh;
    padding: 1rem;
  }

  padding: 2rem;
  border: 1px solid #fff;
  border-radius: 2rem;
  height: 100%;
  

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


