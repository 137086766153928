

.footer{
  display: flex;
  justify-content: center;
  color: #000;
  p{
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;

    @screen lg{
      margin: 40px 0;
      font-size: 1rem;
    }
  }
}