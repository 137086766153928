

.add_more_people{
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(#fff, 0.1);
  border-radius: 1rem;
  padding: 1rem;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  text-align: center;

  @screen lg{
    text-align: left;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    flex-direction: row;
    font-size: 24px;
    padding: 2.625rem;
  }

  span{
    font-weight: 500;
  }

  &__buttons{
    display: flex;
    align-items: center;
    margin-top: 1rem;
    
    @screen lg{
      margin-top: 0;
      margin-left: 3.5rem;
    }
  }

  &__button{
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 29px 9px 9px;
    border-radius: 2rem;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    background-color: var(--blue);
    cursor: pointer;

    &::before{
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border: 2px solid #fff;
      border-radius: 2rem;
      margin-right: 20px;
    }

    &:hover{
      background-color: #000;
    }

    &--checked{
      color: #000;
      &::before{
        border-color: #000;
        background-color: #000;
      }

      &:hover{
        color: #fff;
        
        &::before{
          border-color: #fff;
        }
      }
    }
  }

  &__button + &__button{
    margin-left: 1rem;
  }
}