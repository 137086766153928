$base: './fonts/';


@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('#{$base}HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('#{$base}HelveticaNowDisplay-RegIta.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-RegIta.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('#{$base}HelveticaNowDisplay-Light.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('#{$base}HelveticaNowDisplay-MedIta.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-MedIta.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('#{$base}HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('#{$base}HelveticaNowDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times Newer Roman';
    src: url('#{$base}TimesNewerRoman-Italic.woff2') format('woff2'),
        url('#{$base}TimesNewerRoman-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times Newer Roman';
    src: url('#{$base}TimesNewerRoman-BoldItalic.woff2') format('woff2'),
        url('#{$base}TimesNewerRoman-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Times Newer Roman';
    src: url('#{$base}TimesNewerRoman-Regular.woff2') format('woff2'),
        url('#{$base}TimesNewerRoman-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Times Newer Roman';
    src: url('#{$base}TimesNewerRoman-Bold.woff2') format('woff2'),
        url('#{$base}TimesNewerRoman-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
