

.booking{
  &__title{
   
    font-size: 3.5rem;
    font-weight: 500;
    line-height: 100%;
    margin: 0;
    margin-bottom: 0.5rem;
     margin-top: 2rem;
    @screen lg{
      margin-top: 2.5rem;
      font-size: 200px;
      margin-bottom: 2.5rem;
    }
  }

  &__submit_button{
    border: 0;
    width: 100%;
    border-radius: 2rem;
    font-size: 200px;
    font-weight: 500;
    overflow: hidden;
    color: var(--blue);
    position: relative;

    &--notcoming{
      margin-top: 1rem;

      @screen lg{
        margin-top: 2.5rem;
      }
    
    }

    &:hover{
      .booking__submit_button__first{
        transform: translate(100%, 0);
      }
    }

    &__first{
      background-color: #fff;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px 20px;
      transition: transform .7s ease-out;

      @screen lg{
        transition: transform .7s ease-out .5s;
      }
    }

    &__second{
      display: block;
      background-color: #000;
      width: 100%;
      height: 100%;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px 20px;
    }

    @screen lg{
      &__first,
      &__second{
        padding: 140px 72px;
      }
    }
  }
}