

:root{
  --blue: #2C72FF;
}

*{
  box-sizing: border-box;
}

body{
  background-color: var(--blue, #000);
  color: #fff;
  font-family: 'HelveticaNowDisplay';
}


section{
  padding: 0 1rem;

  @screen lg{
    padding: 0 2rem;
  }
}