

.finish{
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;

  a{
    width: 80%;
    max-height: 80vh;
    // height: 100%;
    color: var(--blue);
    text-align: right;
    font-size: 1rem;
    font-weight: 500;

    @screen lg{
      font-size: 2rem;
    }
  }

  svg{
    width: 100%;
    height: 100%;
    // flex: none;
  }
}