

.booking{
  &__item{
    
    &__input{
      position: relative;
      border: 1px solid #fff;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;

      @screen lg{
        padding: 0 2.625rem;
      }

      > div{
        display: flex;
        flex-direction: column;

        span{
          white-space: nowrap;
        }
        span + span{
          white-space: nowrap;
          font-size: 9px;

          @screen lg{
            font-size: 14px;
          }
        }
      }

      span{
        text-transform: uppercase;
        flex: none;
        margin-right: 6px;
        font-size: 14px;

        @screen lg{
          margin-right: 2rem;
          font-size: 24px;
        }
      }

      input{
        width: 100%;
        height: 100%;
        padding: 0.5rem 0.5rem;
        background-color: transparent;
        border: 0;
        font-size: 20px;
        color: #fff;

        @screen lg{
          padding: 2rem 2rem;
          font-size: 56px;
        }

        &:focus{
          outline: none;
        }
      }

      &__container{
        display: flex;
        flex-direction: column;
      }
    }
    

    &__input + &__input{
      border-top: 0;
    }
  }
}