

.error_input{
  position: absolute;
  bottom: 0;
  background-color: #ff4545;
  border-radius: 2rem;
  padding: 5px 10px;
  transform: translate(0, 50%);

  left: 10px;

  @screen lg{
    bottom: 30px;
    left: calc(2.625rem - 0px);
  }
}